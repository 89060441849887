<template>
  <div class="grid">
    <div class="col-12">
      <div v-if="$auth.hasRoleConsignatariaAdmin() || $auth.hasRoleAverbar()">
        <proposta-form
          :tipo="tipo"
          :desativarBotao="false"
          :usaSenha="false"></proposta-form>
      </div>
      <div v-else>
        <Panel header="Proposta" class="mt-3">
          <h6>
            {{ $auth.msgPermissao() }}
          </h6>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import propostaForm from '@/components/shared/consignacao/propostaForm.vue'
import { consignatariaStore } from '@/stores/consignataria'

export default {
  components: {
    'proposta-form': propostaForm,
  },
  setup() {
    const storeConsignataria = consignatariaStore()
    return { storeConsignataria }
  },

  computed: {
    tipo() {
      return 'NOVA_AVERBACAO'
    },
  },
}
</script>

<style scoped>
h6 {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #c82333;
}
</style>
